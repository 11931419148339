export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/editor/[[gid]]": [~5],
		"/edit/gallery/[[gid]]": [~3],
		"/edit/template/[[tid]]": [~4],
		"/gallery": [6],
		"/gallery/[gid]": [~7],
		"/learnShader": [8],
		"/testBake": [15],
		"/testBake/1": [16],
		"/testDecal": [17],
		"/testDraw": [18],
		"/testGlb": [19],
		"/testProj": [20],
		"/testShader": [21],
		"/testSvg": [22],
		"/test": [9],
		"/test/basic": [10],
		"/test/coffee": [11],
		"/test/device": [12],
		"/test/editimg": [13],
		"/test/pan": [14]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';